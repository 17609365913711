import React from 'react';
import Layout from './src/components/layout';

export function wrapPageElement ({element, props}) {
    return (
        <Layout {...props}>{element}</Layout> 
    )
}

export function wrapRootElement ({element, props}) {
    return (
        <html lang="en">{element}</html> 
    )
}