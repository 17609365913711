import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import NavbarTop from '../components/navBarTop'
import NavBarMobile from "./navBarMobile";
import { Link } from "gatsby";
import '../styles/hero-styles.css'
import '../styles/navBarTop-styles.css'
import '../styles/navBar-styles.css'
import { HomePageAlert } from './homepageAlert'

export default function Hero({ }) {

    const [navbar, setNavbar] = useState('position__top');

    const changeBackground = () => {
        //console.log(window.scrollY)
        if (window.scrollY >= 80) {
            setNavbar('position__scroll')
        } else {
            setNavbar('position__top')
        }
    }

    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })

    return (
        <>
            <NavbarTop location="home" navbar={navbar} />
            <NavBarMobile location="home" navbar={navbar} />

            <section className="hero">
                {/* <div style={{ background: `rgba(255,255,255,.2)`, width: `100%`, height: `25%`, position: `absolute`, bottom: `0%`, zIndex: 0, clipPath: 'polygon(0 15%, 100% 0%, 100% 80%, 0% 100%)' }}></div>
                <div style={{ background: `rgba(255,255,255,.2)`, width: `100%`, height: `15%`, position: `absolute`, bottom: `4%`, zIndex: 0, clipPath: 'polygon(0% 47%, 100% 4%, 100% 37%, 0% 90%)' }}></div>
                <div style={{ background: `rgba(255,255,255,.2)`, width: `100%`, height: `15%`, position: `absolute`, bottom: `8%`, zIndex: 0, clipPath: 'polygon(0 0, 100% -30%, 100% 50%, 0% 50%)' }}></div> */}
                <Container className="headline">
                    <Row>
                        <Col md={4} lg={6}></Col>
                        <Col md={8} lg={6}>
                            <h1>Making a <span>UChicago</span> education <span>attainable.</span></h1>
                        </Col>
                    </Row>
                </Container>
                <Container className="areaButtons"><Row className="justify-content-center">
                    <div className="col-10 justify-content-center">
                        <button title="undergraduate aid"><div className="text-container"><Link to="/undergraduate">undergraduate</Link></div></button>
                        <button title="graduate aid"><div className="text-container"><Link to="/graduate">graduate</Link></div></button>
                    </div>
                </Row></Container>
            </section>
            <HomePageAlert />
        </>
    )
}