import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const HomePageAlert = () => {
  const data = useStaticQuery(graphql`
    query alertQuery {
      contentfulAnnouncement(alertOrAnnouncement: { eq: "Homepage Alert" }) {
        id
        title
        dateTime
        showhideDate
        description {
          raw
        }
        alertOrAnnouncement
      }
    }
  `);

  const current = new Date();
  const date = `0${current.getMonth() + 1}/0${current.getDate()}/${current.getFullYear()}`;

  if (data.contentfulAnnouncement !== null && date <= data.contentfulAnnouncement.dateTime) {
    return (
      <>
        <>
          <section className="hero__alert">
            <Container>
              <Row className="justify-content-center">
                <Col md={9}>
                  <FontAwesomeIcon icon={faBell} />{" "}
                  {data.contentfulAnnouncement.title ? (<p>{data.contentfulAnnouncement.title}</p>) : null}
                  {/* {renderRichText(data.contentfulAnnouncement.description)} */}

                  {data.contentfulAnnouncement.description ? (<>{renderRichText(data.contentfulAnnouncement.description)}</>) : null}

                  {/* <p>{moment(data.contentfulAnnouncement.dateTime).format("MMMM Do YYYY")}</p> */}

                </Col>
              </Row>
            </Container>
          </section>
        </>
      </>
    );
  } else if (data.contentfulAnnouncement === null || data.contentfulAnnouncement.dateTime <= date) {
    return (
      <>
        <section className="hero__alert">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <p>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
};