import React from "react";
import { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import "../styles/navBarMobile-styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";
import Logo from '../images/financial-aid-logo-reversed.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { icon } from "@fortawesome/fontawesome-svg-core";

export default function NavbarMobile({ location }) {


    const data = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    menuLinks {
                        pagePath
                        name
                        fALevel1 {
                            pagePath
                            name
                            fALevel2 {
                                pagePath
                                name
                                fALevel3 {
                                    pagePath
                                    name
                                    # fALevel4 {
                                    #     pagePath
                                    #     name
                                    # }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    // Hook
    function useLocalStorage(key, initialValue) {

        const isBrowser = () => typeof window !== "undefined"
        // State to store our value
        // Pass initial state function to useState so logic is only executed once
        const [storedValue, setStoredValue] = useState(() => {
            if (isBrowser() && typeof window.location.pathname === "/") {
                return initialValue;
            }

            try {
                // Get from local storage by key
                const item = window.localStorage.getItem(key);
                // Parse stored json or if none return initialValue
                return item ? JSON.parse(item) : initialValue;
            } catch (error) {
                // If error also return initialValue
                console.log(error);
                return initialValue;
            }
        });

        // Return a wrapped version of useState's setter function that ...
        // ... persists the new value to localStorage.
        const setValue = (value) => {

            try {
                // Allow value to be a function so we have same API as useState
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                // Save state
                setStoredValue(valueToStore);
                // Save to local storage
                if (isBrowser() && typeof window.location.pathname !== "undefined") {
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                console.log(error);
            }
        };

        return [storedValue, setValue];
    }

    // const [area, setArea] = useState();
    const [name, setName] = useLocalStorage("pageplace", "unset")
    // const [secondary, setSecondary] = useState();
    const [isActive, setActive] = useState();
    const [openClose, setOpenClose] = useState(false);
    const [iconOpenClose, setIconOpenClose] = useState(null);
    const [iconOpenCloseSub, setIconOpenCloseSub] = useState(null);
    const [iconOpenCloseSubSub, setIconOpenCloseSubSub] = useState(null);
    const [listOpenClose, setListOpenClose] = useState(false);
    const [navurl, setNavUrl] = useState(null);
    const isBrowser = () => typeof window !== "undefined"
    const pathName = isBrowser() && window.location.pathname;
    const myElementRef = useRef(null);


    useEffect(() => {
        setNavUrl(window.location.pathname);
        // let secondaryPath = window.location.pathname.replace(/^\/([^\/]*).([^\/]*).*$/, '$2');
        let shortPath = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
        setName(shortPath);
        // setSecondary(secondaryPath);
    }, [(isBrowser() && window.location.pathname)]);

    const handleToggle = () => {
        setOpenClose(prevToggle => !prevToggle);
        // Select all <li> elements with the class of 'active'
        const activeLiElements = document.querySelectorAll('a.open, span.open');
        // Iterate over the selected elements
        activeLiElements.forEach((aElement) => {
            // Remove the 'active' class from each element
            aElement.classList.remove('open');
            aElement.classList.add('closed')
        });
        setIconOpenClose(null)
        setIconOpenCloseSub(null)

    };

    const handleToggleSub = () => {
        setIconOpenCloseSub(prevToggle => !prevToggle);
        // Select all <li> elements with the class of 'active'
        const activeLiElements = document.querySelectorAll('a.open, span.open');
        // Iterate over the selected elements
        activeLiElements.forEach((aElement) => {
            // Remove the 'active' class from each element
            aElement.classList.remove('open');
            aElement.classList.add('closed')
        });
        setIconOpenCloseSub(null)
        handleToggle();
    };
    const handleToggleSubSub = () => {
        setIconOpenCloseSubSub(prevToggle => !prevToggle);
        // Select all <li> elements with the class of 'active'
        const activeLiElements = document.querySelectorAll('a.open, span.open');
        // Iterate over the selected elements
        activeLiElements.forEach((aElement) => {
            // Remove the 'active' class from each element
            aElement.classList.remove('open');
            aElement.classList.add('closed')
        });
        setIconOpenCloseSubSub(null)
        handleToggle();
    };


    const handleIcon = (event, i) => {

        const openElement = event.currentTarget;

        //const openItems = rootRef.current.querySelectorAll('a.open');
        if (openElement.classList[0] === 'closed') {
            openElement.classList.remove('closed');
            openElement.classList.add('open')
            setIconOpenClose(i)
        } else if (openElement.classList[0] === 'open') {
            openElement.classList.remove('open');
            openElement.classList.add('closed');
            setIconOpenClose(null)
        }

    }
    const handleIconSub = (eventSub, i) => {
        const openElementSub = eventSub.currentTarget;
        console.log('sub is: ' + openElementSub.classList[0])
        if (openElementSub.classList[0] === 'closed') {
            openElementSub.classList.remove('closed');
            openElementSub.classList.add('open')
            setIconOpenCloseSub(i)
        } else if (openElementSub.classList[0] === 'open') {
            openElementSub.classList.remove('open');
            openElementSub.classList.add('closed');
            setIconOpenCloseSub(null)
        }

    }

    const handleIconSubSub = (eventSubSub, i) => {
        const openElementSubSub = eventSubSub.currentTarget;
        console.log('sub is: ' + openElementSubSub.classList[0])
        if (openElementSubSub.classList[0] === 'closed') {
            openElementSubSub.classList.remove('closed');
            openElementSubSub.classList.add('open')
            setIconOpenCloseSubSub(i)
        } else if (openElementSubSub.classList[0] === 'open') {
            openElementSubSub.classList.remove('open');
            openElementSubSub.classList.add('closed');
            setIconOpenCloseSubSub(null)
        }

    }

    let pathname = '';
    if (typeof window !== 'undefined') {
        pathname = window.location.pathname;
    }

    return (
        <div className={`navbar__mobile ${(openClose === true) ? 'open' : ''} `}>
            <section >
                <Container>
                    <Row>
                        <header className="header__navbarTop">

                            {/* financial aid logo */}
                            <Link className="navbarTop__logo" to="/" onClick={() => setName('home')}><img src={Logo} alt="Financial Aid Logo Home Link" /></Link>

                            {/* hamburger menu */}
                            <Link className="openClose" to="" onClick={handleToggle} alt="open and close">
                                {openClose ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
                            </Link>

                            <ul>
                                {data.site.siteMetadata.menuLinks.map((nav, i) => (
                                   
                                        <li>
                                            {/* named links */}
                                            <Link onClick={handleToggle}
                                                activeClassName='active'
                                                to={nav.pagePath}
                                                className={name === nav.name ? 'active__area' : ''}>
                                                {nav.name}</Link>

                                            {/* toggle arrow to the right */}
                                            {Array.isArray(nav.fALevel1) !== false && (

                                                <Link key={i} to="" className={`${iconOpenClose === i ?
                                                    "open" :
                                                    "closed"
                                                    }`}
                                                    onClick={(event) => handleIcon(event, i)}
                                                >
                                                    {iconOpenClose === i ? <FontAwesomeIcon icon={faChevronDown} className={`fa-solid fa-sharp`} /> : <FontAwesomeIcon icon={faChevronRight} className="fa-solid fa-sharp" />}

                                                </Link>
                                            )}

                                            {Array.isArray(nav.fALevel1) !== false &&
                                                nav.fALevel1.map((nav1, i) => (
                                                    <ul className={`${listOpenClose === i ? 'plus' : 'minus'}`}>
                                                        <li>

                                                            {nav1.pagePath.includes('/undergraduate/') ?
                                                                <Link onClick={handleToggleSub}
                                                                    activeClassName='active'
                                                                    to={nav1.pagePath}

                                                                    className={name === nav1.name ? '' : ''}
                                                                >

                                                                    {nav1.name}

                                                                </Link> : <span

                                                                    onClick={handleToggleSub}
                                                                    // activeClassName='active'
                                                                    href={nav1.pagePath}

                                                                    className={name === nav1.name ? '' : ''}


                                                                >{nav1.name}</span>}


                                                            {/* toggle arrow to the right */}
                                                            {Array.isArray(nav1.fALevel2) !== false && (

                                                                <Link key={i} to="" className={`${iconOpenCloseSub === i ?
                                                                    "open" :
                                                                    "closed"
                                                                    }`}
                                                                    onClick={(eventSub) => handleIconSub(eventSub, i)}
                                                                >
                                                                    {iconOpenCloseSub === i ? <FontAwesomeIcon icon={faChevronDown} className={`fa-solid fa-sharp`} /> : <FontAwesomeIcon icon={faChevronRight} className="fa-solid fa-sharp" />}

                                                                </Link>
                                                            )}

                                                            {Array.isArray(nav1.fALevel2) !== false &&
                                                                nav1.fALevel2.map((nav2, i) => (
                                                                    <ul className={`${listOpenClose === i ? 'plus' : 'minus'}`}>
                                                                        <li>

                                                                            {!nav2.name.includes('Eligibility') && !nav2.name.includes('Aid Disbursements and Refunds') ?
                                                                                <Link onClick={handleToggle}
                                                                                    activeClassName='active'
                                                                                    to={nav2.pagePath}

                                                                                    className={name === nav2.name ? '' : ''}
                                                                                >
                                                                                    {nav2.name}

                                                                                </Link> : <span>{nav2.name}</span>}

                                                                            {Array.isArray(nav2.fALevel3) !== false && (

                                                                                <Link key={i} to="" className={`${iconOpenCloseSubSub === i ?
                                                                                    "open" :
                                                                                    "closed"
                                                                                    }`}
                                                                                    onClick={(eventSubSub) => handleIconSubSub(eventSubSub, i)}
                                                                                >
                                                                                    {iconOpenCloseSubSub === i ? <FontAwesomeIcon icon={faChevronDown} className={`fa-solid fa-sharp`} /> : <FontAwesomeIcon icon={faChevronRight} className="fa-solid fa-sharp" />}

                                                                                </Link>
                                                                            )}

                                                                            {Array.isArray(nav2.fALevel3) !== false &&
                                                                                nav2.fALevel3.map((nav3, i) => (
                                                                                    <ul className={`${listOpenClose === i ? 'plus' : 'minus'}`}>
                                                                                        <li>


                                                                                            <Link onClick={handleToggleSubSub}
                                                                                                activeClassName='active'
                                                                                                to={nav3.pagePath}

                                                                                                className={name === nav1.name ? '' : ''}
                                                                                            >

                                                                                                {nav3.name}

                                                                                            </Link>



                                                                                        </li>
                                                                                    </ul>
                                                                                ))
                                                                            }
                                                                        </li>

                                                                    </ul>
                                                                ))}

                                                        </li>
                                                    </ul>


                                                ))}
                                                                        
                                        </li>)
                                            
                                                )}              
                                    </ul>

                                                



                                    </header >
                    </Row>
                </Container>
            </section>
            {/* NAVBAR INSERT BELOW */}

            {/* <Navbar name={name} navurl={navurl} /> */}
        </div >
    )
}