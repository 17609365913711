import * as React from "react"
import { useState, useEffect } from "react"
import Hero from "../components/hero"
import '../styles/styles.css'
import NavbarTop from "../components/navBarTop"
import NavbarMobile from "../components/navBarMobile"
import SectionHead from "./sectionHead"
import Footer from "../components/footer"
import ExternalLinks from "./externalLink"
import '../fonts/hco_fonts/hco_fonts.css'


export default function Layout({ children }) {

    const [url, setUrl] = useState(null);
    const isBrowser = () => typeof window !== "undefined";

    useEffect(() => {
        setUrl(window.location.pathname);
    }, [(isBrowser() && window.location.pathname)]);

    if (url === "/") {
        return (
            <>
                <Hero />
                
                <main className="main">
                    {children}
                </main>
                <Footer />
            </>
        )
    } else if(url === '/undergraduate/' || url === '/graduate/'){
        return (
            <>
                <NavbarTop />
                <NavbarMobile />
                <SectionHead url={url}/>
                <main className="main">
                    {children}
                </main>
                <Footer />
            </>
        )
    } else {
        return (
            <>
                <NavbarTop />
                <NavbarMobile />
                <main className="main">
                    {children}
                </main>
                <Footer />
            </>
        )
    }
}




