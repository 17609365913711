import React from "react"
import { Container, Row, Col } from "react-bootstrap"

export default function SectionHead({ url }) {
    
    const topper = (() => {
        if (url === "/undergraduate/") {
          return 'https://images.ctfassets.net/xrhc6s36njjr/1hxaCh20EX8HkVSKtX5pik/418b03a3d704ab4fdd4b6847764711a5/students-on-quad-several.webp';
        } else if (url === "/graduate/") {
          return 'https://images.ctfassets.net/xrhc6s36njjr/1hxaCh20EX8HkVSKtX5pik/d72732abc584f294085a798e18eddcea/grad-students.webp';
        } else {
          return 'https://images.ctfassets.net/xrhc6s36njjr/19fFArK7YtQXuFzTcF2jdK/fc9b063d596c048ce611a23071054267/quad-circle.jpg';
        }
      })();

    return (
        <>
            <section style={{display: 'flex', 
            alignItems: 'center', 
            maxHeight: 'calc(75vh - 600px)', 
            minHeight: '360px',
            overflow: 'hidden', 
            minHeight: '360px'
            
            }}>
                
                        <img alt="students walking on uchicago campus" src={topper} style={{ width: '100%', height: 'auto', transform: 'translateY(4%)'}}/>
                      
               
            </section>
        </>
    )
}