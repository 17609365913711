import React from "react";
import { useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import "../styles/navBar-styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";

export default function Navbar({ name, navurl, secondary }) {
    const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          menuLinks {
            pagePath
            name
            fALevel1 {
              pagePath
              name
              fALevel2 {
                pagePath
                name
                fALevel3 {
                  pagePath
                  name
                #   fALevel4 {
                #     pagePath
                #     name
                #   }
                }
              }
            }
          }
        }
      }
    }
  `);

    useEffect(() => {
        const el = document.querySelectorAll("ul.active > li");
        const lastEl = el[el.length - 1];
        if (lastEl != null) {
            lastEl.classList.add("last");
        }
    }, []);
   
    let pathname = '';
if (typeof window !== 'undefined') {
  pathname = window.location.pathname;
}
    
    return (
        <>
            <section className={navurl === "/" ? "navbar home " : "navbar"}>
                <Container>
                    <Row>
                        <nav>
                            {data.site.siteMetadata.menuLinks.map((nav) => (
                                <>
                                    {/* LEVEL 1 */}

                                    {/* this bit shows only the area we are in based on the area set in navbarTop.js  */}
                                    <ul className={((nav.name) === (name)) ? 'active' : 'inactive'}>

                                        {Array.isArray(nav.fALevel1) !== false &&
                                            nav.fALevel1.map((nav1, i) => (
                                                <li
                                                    key={nav1.name}
                                                    className={
                                                        nav1.fALevel2 != null ? "level1" : "nochildren"
                                                    }
                                                   
                                                >
                                                    {pathname.includes('/undergraduate/') ? 
                                                    <Link title={nav1.name} className={secondary !== "" && nav1.pagePath.includes(secondary) ? 'active' : 'inactive'} to={nav1.pagePath}>{nav1.name}</Link> : <span>{nav1.name}</span>}

                                                    {/* LEVEL 2 */}
                                                    <ul key={i}>
                                                        {Array.isArray(nav1.fALevel2) &&
                                                            nav1.fALevel2.map((nav2, i) => (
                                                                <li
                                                                    key={nav2.name}
                                                                    
                                                                    className={
                                                                        nav2.fALevel3 != null
                                                                            ? "level2"
                                                                            : "nochildren"
                                                                    }
                                                                >
                                                                    {!nav2.name.includes('Eligibility') && !nav2.name.includes('Aid Disbursements and Refunds') ? 
                                                                    <Link title={nav2.name} to={nav2.pagePath}>{nav2.name}</Link> :
                                                                    <span>{nav2.name}</span>}
                                                                    {/* LEVEL 3 */}
                                                                    <ul key={i}>
                                                                        {Array.isArray(nav2.fALevel3) &&
                                                                            nav2.fALevel3.map((nav3) => (
                                                                                <li
                                                                                    key={nav3.name}
                                                                                    className={
                                                                                        nav2.fALevel3 != null
                                                                                            ? "level3"
                                                                                            : "nochildren"
                                                                                    }
                                                                                >
                                                                                    <Link to={nav3.pagePath}>
                                                                                        {nav3.name}
                                                                                    </Link>
                                                                                    {/* LEVEL 4 */}
                                                                                    {/* <ul key={i}>
                                                                                        {Array.isArray(nav3.fALevel4) &&
                                                                                            nav3.fALevel4.map((nav4) => (
                                                                                                <li
                                                                                                    key={nav4.name}
                                                                                                    className={
                                                                                                        nav3.fALevel4 != null
                                                                                                            ? "level4"
                                                                                                            : "nochildren"
                                                                                                    }
                                                                                                >
                                                                                                    <Link to={nav4.pagePath}>
                                                                                                        {nav4.name}
                                                                                                    </Link>
                                                                                                </li>
                                                                                            ))}
                                                                                    </ul> */}
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            ))}
                        </nav>
                    </Row>
                </Container>
            </section>
        </>
    );
}
