import React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import '../styles/footer-styles.css'
import Crescat from '../images/crescat.svg'

export default function Footer({ children }) {

    return (
        <footer>
            <Container>
                <Row className="justify-content-center">
                    <Col md={3}>
                        <p><strong>The University of Chicago</strong><br />
                            Financial Aid<br />
                            1101 E. 58th Street<br />
                            Walker 101<br />
                            Chicago, IL 60637</p>
                    </Col>
                    <Col className="d-md-none d-xs-block" md={3}><hr /></Col>
                    <Col xs={6} md={3}>
                        <ul>
                            <li><a href="https://maps.uchicago.edu/" target="_blank">Maps</a></li>
                            <li><a href="https://my.uchicago.edu/" target="_blank">My.UChicago</a></li>
                            <li><a href="https://www.uchicago.edu/jobs/" target="_blank">Job Opportunities</a></li>
                            <li><a href="https://titleixpolicy.uchicago.edu/" target="_blank">Title IX</a></li>
                            <li><a href="https://www.uchicago.edu/about/non_discrimination_statement/" target="_blank">Non-discrimination</a></li>
                            <li><a href="https://accessibility.uchicago.edu/" target="_blank">Accessibility</a></li>
                            <li><a href="https://privacy.uchicago.edu/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </Col>
                    <Col className="d-none d-md-block" md={3}></Col>
                    <Col className="crescat" xs={6} md={3}><img src={Crescat} alt="crescate scientia book image" /></Col>
                </Row></Container>
        </footer>
    )
}